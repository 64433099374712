// $theme-colors: (
//     "info": tomato,
//     "danger": teal
// );

$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px
);

@mixin respond-below($breakpoint) {

    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {

        // Get the breakpoint value.
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        // Write the media query.
        @media (max-width: ($breakpoint-value - 1)) {
        @content;
        }

    // If the breakpoint doesn't exist in the map.
    } @else {

        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

.float-right {
    float: right;
}

body {
    background: url('./1024-1024.png') no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #000 !important;
    padding-top: 0px;
    padding-bottom: 0px;
}

.container {
    min-height: 68vh;
}

.privacyPolicy {
    background: #ffffffb3;
    margin-bottom: 20px;
}

.footer {
    padding: 5px;
    background-color: #f7f7f7c2 !important;
}

.product, .login, .remember {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    background-color: #f7f7f7c2 !important;
    span {
        font-size: 14px;
    }
    h1 {
        font-family: monospace;
    }
}
.login, .remember {
    .form-group{
        margin-bottom: 7px;
        .form-label {
            margin-bottom: 0;
        }
    }
}

.header {
    background-color: #ffffff70;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    h1 {
        font-family: monospace;
        font-size: 33px;
        margin: 0;
        height: 47px;
        
        text-align: center;
    }
}

@include respond-below(xs) {
    .header h1 {
        font-size: 22px;
    }
}

@import "~bootstrap/scss/bootstrap";